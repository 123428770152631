// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventsDisplay_title__xXvEV {
    width: 100%;
    display: flex;
    justify-content: center;

}

.EventsDisplay_maincontainer__KP89A {
    margin: 1rem;
}

.EventsDisplay_innercontainer__pgu7C {
    margin-top: 1rem;
    padding: 10px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;


}

.EventsDisplay_useritem__B8NlO {
    background-color: rgb(233, 231, 231);
    margin-top: 5px;
    border-bottom: 2px solid white;
    cursor: pointer;
}

.EventsDisplay_item__ukGaD {
    padding: 1rem;
    text-align: center;
}

.EventsDisplay_heading__fbvR8 {
    background-color: black;
    color: white;
}

.EventsDisplay_useritem__B8NlO:hover {
    background-color: rgb(224, 224, 224);
}

.EventsDisplay_outercontainer__YtxfE {
    display: flex;
    justify-content: center;
}

.EventsDisplay_buttonscontainer__Cb7Ii {
    display: flex;
    align-items: center;
    gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/EventsDisplay.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;;AAE3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,6CAA6C;IAC7C,mBAAmB;;;AAGvB;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".title {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n\n}\n\n.maincontainer {\n    margin: 1rem;\n}\n\n.innercontainer {\n    margin-top: 1rem;\n    padding: 10px;\n    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);\n    border-radius: 10px;\n\n\n}\n\n.useritem {\n    background-color: rgb(233, 231, 231);\n    margin-top: 5px;\n    border-bottom: 2px solid white;\n    cursor: pointer;\n}\n\n.item {\n    padding: 1rem;\n    text-align: center;\n}\n\n.heading {\n    background-color: black;\n    color: white;\n}\n\n.useritem:hover {\n    background-color: rgb(224, 224, 224);\n}\n\n.outercontainer {\n    display: flex;\n    justify-content: center;\n}\n\n.buttonscontainer {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `EventsDisplay_title__xXvEV`,
	"maincontainer": `EventsDisplay_maincontainer__KP89A`,
	"innercontainer": `EventsDisplay_innercontainer__pgu7C`,
	"useritem": `EventsDisplay_useritem__B8NlO`,
	"item": `EventsDisplay_item__ukGaD`,
	"heading": `EventsDisplay_heading__fbvR8`,
	"outercontainer": `EventsDisplay_outercontainer__YtxfE`,
	"buttonscontainer": `EventsDisplay_buttonscontainer__Cb7Ii`
};
export default ___CSS_LOADER_EXPORT___;
