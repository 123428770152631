import React, { useState, useEffect } from 'react';
import { faHome, faMapMarked, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './styles/Navbar.module.css'
import logoImage from './assetts/logo.png'
import { AppRegistrationOutlined, AccountCircle, SettingsOverscanSharp, Visibility, VisibilityOff } from '@mui/icons-material';
// import { ApartmentRounded } from '@mui/icons-material';
// import { VillaOutlined } from '@mui/icons-material';
// import { HomeOutlined } from '@mui/icons-material';
import { LandslideSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Login } from '@mui/icons-material';
import { Logout } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import { RoomServiceSharp } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, Snackbar, TextField } from '@mui/material';
import { forgotPassword, getUser, resetPassword, sendOtp, userLogin, validateOtp, getUserData } from './services/Api/Users';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OTPInput from './Pages/OTPInput';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FormDialog from './Pages/FormDialog';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import ServicesDialog from './Pages/ServicesDialog';
import logo from '../src/media/Logo-yellow.jpg';
import logo2 from '../src/media/Logo-latest-web.png';
import latest from '../src/media/Suman-cdr.png';
import latest3 from '../src/media/Suman-GM Logo.png';

interface NavbarProps {
  title: string;
  links: { label: string; url: string; icon: JSX.Element }[];
}

const Navbar: React.FC<NavbarProps> = ({ title, links }) => {
  const navigate = useNavigate()
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [otpLogin, setOtpLogin] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [otp, setOtp] = useState<string>('');
  const [showToken, setShowToken] = useState(false)

  const handleOtpChange = (value: string) => {
    setOtp(value);
  };

  const openPopup = () => {
    setEmail('')
    setPassword('')
    setOtpLogin(false)
    setIsPopupOpen(true);
    setIsForgot(false)
    setToken('')
    setShowToken(false)
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  }
  const routeTo = (e: any) => {
    navigate(e)
  }
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarContent, setSnackbarContent] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [isForgot, setIsForgot] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [token, setToken] = useState('')

  const handleSubmit = async () => {

    const payload = {
      email: email,
      password: password
    }

    try {
      const response = await userLogin(payload)
      if (response.success) {
        setSnackbarContent("Login Successful")
        setSnackbarOpen(true)
        closePopup()
        console.log("response", response)
        let user_response = await getUser(response.user_id)
        // user_response.role = 'ADMIN'
        if (user_response.role == 'ADMIN') {
          navigate('/adminhome')

        }
        console.log(user_response)
        if (user_response.success) {
          if (user_response.data) {
            localStorage.setItem("user", JSON.stringify(user_response.data))
            setCurrentUser(user_response.data)

          }
        }
      }
      else {
        setSnackbarContent(response.msg)
        setSnackbarOpen(true)
      }
    }
    catch (error) {
      setSnackbarContent("Error occured")
      setSnackbarOpen(true)
    }
  }
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("user")
    setCurrentUser('')
    navigate('/home')
  }
  const handleOTPSubmit = async () => {
    console.log("User")

    try {
      const response = await sendOtp({ email })
      console.log(response)
      setSnackbarOpen(true);

      handleClickOpen()
      closePopup()

    }
    catch (err) {
      console.log("Error occured");
    }

  }
  const handleOTPValidate = async () => {
    try {
      const response = await validateOtp({ otp: otp, email: email, service: "login" })
      console.log(email)

      if (response.success) {
        console.log(response.success)
        handleClose();
        setSnackbarContent("OTP validated succesfully")
        setSnackbarOpen(true)

        const param = {
          email: email

        }

        const user_response = await getUserData(param)

        // console.log("user_response", user_response)
        if (user_response.role == 'ADMIN') {
          navigate('/adminhome')

        }
        // console.log(user_response)
        if (user_response.success) {
          if (user_response.data) {
            localStorage.setItem("user", JSON.stringify(user_response.data))
            setCurrentUser(user_response.data)

          }
        }



        // await handleSubmit();
      }
      else {
        // setSnackbarContent("Failed")
        // setSnackbarOpen(true)
        setErrorMessage("Invalid OTP. Please try again.");
        setErrorDialogOpen(true);
      }
    }
    catch (err) {
      // setSnackbarContent("Failed Try Again")
      // setSnackbarOpen(true)
      setErrorMessage("Invalid OTP. Please try again.");
      setErrorDialogOpen(true);
      handleClose();
    }
  }
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user != null) {
      setCurrentUser(user)
    }
  }, [])

  const handleForgotPassword = () => {
    setIsForgot(true)
  }
  const handlSubmitForgotPassword = async () => {
    try {

      const response = await forgotPassword({ email: email })
      setShowToken(true)
      setSnackbarContent("Token has been sent to your mail")
      setSnackbarOpen(true)

    }
    catch (err) {
      console.log("Error occurred")
    }
  }
  const handleResetPassword = async () => {
    try {

      const response = await resetPassword(token, { password: password })
      if (response.success) {
        setSnackbarContent("Password Reset Successfull")
        setSnackbarOpen(true)
        closePopup()
      }
    }
    catch (err) {
      console.log("Error occured")
    }
  }
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpenDispute = () => {
    setIsOpen(true);
  };

  const handleCloseDispute = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div className={style.maincontainer}>
        <div className={style.innercontainer}>
          {/* <div className={style.titlecontainer} >
            <img src={logoImage}></img>
            <h4>GruhaSamachar</h4>
          </div> */}
          {/* <div className={style.logoContainer}>
            <img src={logoImage} alt="GruhaSamachar Logo" className={style.logo} />
            <h1 className={style.title}>GruhaSamachar.com</h1>
          </div> */}
          <div className={style.logoContainer} >
            <img src={latest3} alt="GruhaSamachar Logo" style={{ width: "350px", height: "50px", marginLeft: "-10px", marginTop: "-10px", marginBottom: "-10px" }} />
          </div>
          <div className={style.linkscontainer}>
            <div className={style.linkitem} onClick={() => routeTo('Home')}>
              <HomeIcon />
              <p className={style.link}>Houses</p>
            </div>
            <div className={style.linkitem} onClick={() => routeTo('/FormLands')}>
              <LandslideSharp />
              <p className={style.link}>Lands</p>
            </div>
            <div className={style.linkitem} onClick={() => setIsOpen(true)}>
              <LocalPostOfficeIcon />
              <p className={style.link}>Services</p>
            </div>
            {/* {
              currentUser == "" && (
                <div className={style.linkitem} onClick={() => routeTo('/Register')}>
                  <AppRegistrationOutlined />
                  <p className={style.link}>Register</p>
                </div>
              )
            } */}
            {
              currentUser == '' && (
                <div className={style.linkitem}>
                  <Login />
                  <p className={style.link} onClick={openPopup}>Login</p>
                </div>
              )
            }
            {
              currentUser != '' && (
                <>
                  <div className={style.linkitem}>
                    <Logout />
                    <p className={style.link} onClick={handleLogout}>Logout</p>
                  </div>
                  <div className={style.linkitem} onClick={() => routeTo('/user-profile')}>
                    <AccountCircleIcon />
                    <p className={style.link}>Profile</p>
                  </div>
                </>

              )
            }
          </div>
        </div>

      </div>


      {isPopupOpen && (
        <div className={style.popupOverlay}>
          <div className={style.popupContent}>
            <div className={style.loginHeader}>
              <h2>Login</h2>
              <button onClick={closePopup} className={style.closeButton}>
                <Close />
              </button>
            </div>

            <div className={style.formContainer}>
              {!isForgot && (
                <>
                  <h5
                    onClick={() => setOtpLogin(true)}
                    className={`${style.toggleMode} ${otpLogin ? style.active : ""}`}
                  >
                    OTP Login
                  </h5>
                  <h5
                    onClick={() => setOtpLogin(false)}
                    className={`${style.toggleMode} ${!otpLogin ? style.active : ""}`}
                  >
                    Email Login
                  </h5>
                </>
              )}

              <div className={style.inputFields}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
                {showToken && (
                  <TextField
                    id="outlined-token"
                    label="OTP Token"
                    variant="outlined"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    fullWidth
                    style={{ marginTop: "1rem" }}
                  />
                )}

                {!otpLogin && !isForgot && (
                  <TextField
                    id="outlined-password"
                    label="Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    style={{ marginTop: "1rem" }}
                  />
                )}
                {/* Submit button for Email Login */}
                {!isForgot && !otpLogin && (
                  <button
                    className={
                      email === "" || password === ""
                        ? style.disabledButton
                        : style.primaryButton
                    }
                    disabled={email === "" || password === ""}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
                {token && isForgot && (
                  <>
                    <TextField
                      id="outlined-password"
                      label="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      style={{ marginTop: "1rem" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      id="outlined-confirm-password"
                      label="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      style={{ marginTop: "1rem" }}
                    />
                  </>
                )}
              </div>


              <div className={style.buttonContainer}>
                <div className={style.buttonRow}>
                  {isForgot && token === "" && (
                    <button
                      className={
                        email === "" ? style.disabledButton : style.primaryButton
                      }
                      disabled={email === ""}
                      onClick={handlSubmitForgotPassword}
                    >
                      Submit
                    </button>
                  )}




                  {/* Submit button for OTP Login */}
                  {!isForgot && otpLogin && (
                    <button
                      className={
                        email === "" ? style.disabledButton : style.primaryButton
                      }
                      disabled={email === ""}
                      onClick={handleOTPSubmit}
                    >
                      Submit
                    </button>
                  )}

                  {/* Reset button for Forgot Password */}
                  {token !== "" && isForgot && (
                    <button
                      className={style.primaryButton}
                      onClick={handleResetPassword}
                    >
                      Reset
                    </button>
                  )}

                  <button className={style.secondaryButton} onClick={closePopup}>
                    Cancel
                  </button>
                </div>
              </div>

              <div className={style.extraContainer}>
                <p>
                  <a
                    className={style.forgotLink}
                    onClick={handleForgotPassword}
                    role="button"
                  >
                    Forgot Password?
                  </a>
                </p>
                <p>
                  Don’t have an account?{" "}
                  <a href="/register" className={style.registerLink}>
                    Register
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        message={snackbarContent}
        onClose={handleSnackbarClose}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Enter OTP</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            An OTP has been sent to your email. Please verify it within 10 minutes.
            <OTPInput length={6} onChange={handleOtpChange} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOTPValidate}
            autoFocus
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* <FormDialog open={isOpen} onClose={handleCloseDispute} /> */}
      <ServicesDialog open={isOpen} onClose={handleCloseDispute} />

      <Dialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">OTP Validation Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setErrorDialogOpen(false); // Close the error dialog
              setOpen(true); // Reopen the OTP input dialog
            }}
            variant="contained"
            color="primary"
          >
            Retry
          </Button>
          <Button
            onClick={() => setErrorDialogOpen(false)}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );
}


// <nav className={style.navbar}>
//   <div className={style.logoContainer}>
//     <img src={logoImage} alt="GruhaSamachar Logo" className={style.logo} />
//     <h1 className={style.title}>GruhaSamachar.Com</h1>
//   </div>
//   <div className={style.navLinks}>
//     <div className={style.link} onClick={() => routeTo('/home')}>
//       <p>All Properties</p>
//     </div>
//     <div className={style.link} onClick={() => routeTo('/services')}>
//       <RoomServiceSharp className={style.icon} />
//       <p>Services</p>
//     </div>
//     {!currentUser ? (
//       <>
//         <div className={style.link} onClick={() => routeTo('/register')}>
//           <AppRegistrationOutlined className={style.icon} />
//           <p>Register</p>
//         </div>
//         <div className={style.link} onClick={() => routeTo('/login')}>
//           <Login className={style.icon} />
//           <p>Login</p>
//         </div>
//       </>
//     ) : (
//       <>
//         <div className={style.link} onClick={handleLogout}>
//           <Logout className={style.icon} />
//           <p>Logout</p>
//         </div>
//         <div className={style.link} onClick={() => routeTo('/profile')}>
//           <AccountCircle className={style.icon} />
//           <p>Profile</p>
//         </div>
//       </>
//     )}
//   </div>
// </nav>


{/* <div className={style.buttonContainer}>
              {isForgot && token === "" && (
                <button
                  className={style.primaryButton}
                  onClick={handlSubmitForgotPassword}
                >
                  Submit
                </button>
              )}
              <button className={style.secondaryButton} onClick={closePopup}>
                Cancel
              </button>

              {!otpLogin && !isForgot && (
                <button
                  className={
                    email === "" || password === ""
                      ? style.disabledButton
                      : style.primaryButton
                  }
                  disabled={email === "" || password === ""}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}

              {otpLogin && !isForgot && (
                <button
                  className={
                    email === ""
                      ? style.disabledButton
                      : style.primaryButton
                  }
                  disabled={email === ""}
                  onClick={handleOTPSubmit}
                >
                  Submit
                </button>
              )}

              {token !== "" && (
                <button
                  className={style.primaryButton}
                  onClick={handleResetPassword}
                >
                  Reset
                </button>
              )}
            </div> */}

{/* {isPopupOpen && (
        <div className={style.popupOverlay}>
          <div className={style.popupContent}>
            <div className={style.loginheader}>
              <h2 >Email LOGIN FORM</h2>
              <div onClick={closePopup} className={style.close}><Close /></div>
            </div>
            <h5 onClick={() => setOtpLogin(true)}>OTP Login</h5>
            <div>
              <div className={style.input}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
                {
                  showToken && (
                    <TextField
                      id="outlined-basic"
                      label="TOKEN"
                      variant="outlined"
                      value={token}
                      onChange={(e) => setToken(e.target.value)}
                      style={{ marginTop: '0.5rem' }}
                      fullWidth
                    />
                  )
                }
              </div>

              <div className={style.input}>
                {
                  !otpLogin && (!isForgot || showToken) && (
                    <>
                      <TextField
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={style.input}
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )
                }
              </div>
            </div>
            <div className={style.loginbuttoncontainer}>
              {
                !isForgot && (
                  <button className={style.closebtn} onClick={handleForgotPassword}>Forgot Password</button>
                )
              }
              {token != '' && (<button className={style.closebtn} onClick={handleResetPassword}>reset</button>)}
              {
                isForgot && token == '' && (
                  <button className={style.submitbtn} onClick={handlSubmitForgotPassword}>Submit</button>
                )
              }
              <button className={style.closebtn} onClick={closePopup}>CANCEL</button>
              {
                !otpLogin && !isForgot && (
                  <button
                    className={(email == '' || password == '') ? style.disablebtn : style.submitbtn}
                    disabled={email == '' || password == ''}
                    onClick={handleSubmit}
                  >SUBMIT</button>

                )
              }
              {
                otpLogin && !isForgot && (
                  <button
                    className={(email == '') ? style.disablebtn : style.submitbtn}
                    disabled={email == ''}
                    onClick={handleOTPSubmit}
                  >SUBMIT</button>
                )
              }
            </div>
          </div>
        </div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        message={snackbarContent}
        onClose={handleSnackbarClose}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Enter the OTP"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>OTP has been sent to your email please verify it expires in 10 minutes</p>
            <OTPInput length={6} onChange={handleOtpChange} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOTPValidate} autoFocus variant="contained" style={{ backgroundColor: 'black' }}>
            SUBMIT
          </Button>
        </DialogActions>LOGIN FORM
      </Dialog>
      <FormDialog open={isOpen} onClose={handleCloseDispute} /> */}


export default Navbar;





{/* <div className={style.linkitem} onClick={() => routeTo('/Apartments')}>
              <ApartmentRounded />
              <p className={style.link}>Apartments</p>
            </div>
            <div className={style.linkitem} onClick={() => routeTo('/Villas')}>
              <VillaOutlined />
              <p className={style.link}>Villas</p>
            </div>
            <div className={style.linkitem} onClick={() => routeTo('/IndividualHomes')}>
              <HomeOutlined />
              <p className={style.link}>Individual Home</p>
            </div>
            <div className={style.linkitem} onClick={() => routeTo('/FormLands')}>
              <LandslideSharp />
              <p className={style.link}>Lands</p>
            </div> */}