import React, { useEffect, useState } from "react";
import style from '../styles/HomePage.module.css'
import { getAdminAllProperties, getProperties } from "../services/Api/Property";
import PropertyCard from "./PropertyCard";

export function AdminProperties() {

  const [data, setData] = useState([])

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getAdminAllProperties("PENDING");

        if (response.success) {
          setData(response.data)
        }
      }
      catch (err) {
        console.log("Error occurred")
      }

    }
    getData();
  }, [])

  return (
    <>
      <div><h3>PROPERTIES</h3></div>
      <div className={style.propertycontainer}>


        {
          data && data.length > 0 && data.map((item, index) => {
            return (
              <div key={index}>
                <PropertyCard property_obj={item} showButtons={false} showApprove={true} />
              </div>
            )
          })
        }    {
          data && data.length == 0 && (
            <>
              <h3>No Properties to Approve</h3>
            </>
          )
        }
      </div>

    </>
  )
}

export default AdminProperties;