// FormDialog.js
import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { createDispute } from '../services/Api/Dispute';
import styles from '../styles/DisputeFormdialogue.module.css'


interface FormProps {

    open: boolean,
    onClose: () => void;
}
const FormDialog: React.FC<FormProps> = ({ open, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile_number: '',
        problem_title: '',
        problem_description: '',
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        console.log('Form Data Submitted:', formData);
        const payload = {
            name: formData.name,
            email: formData.email,
            problem_title: formData.problem_title,
            problem_discription: formData.problem_description,
            mobile_number: parseInt(formData.mobile_number)
        }
        try {
            const response = await createDispute(payload);
            if (response.success) {
                alert("Dispute submitted successfully.")
            }
            else {
                alert("Something went wrong")
            }
            onClose();
        } catch (error) {
            alert("Error Occured")
            onClose();
        }

    };

    return (


        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className={styles.dialogTitle} >
                Enter your Details
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChange}
                        className={styles.inputField}
                    />
                    <TextField
                        margin="dense"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChange}
                        className={styles.inputField}
                    />
                    <TextField
                        margin="dense"
                        name="mobile_number"
                        label="Phone Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.mobile_number}
                        onChange={handleChange}
                        className={styles.inputField}
                    />
                    <TextField
                        margin="dense"
                        name="problem_title"
                        label="Problem Title"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.problem_title}
                        onChange={handleChange}
                        className={styles.inputField}
                    />
                    <TextField
                        margin="dense"
                        name="problem_description"
                        label="Problem Description"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={formData.problem_description}
                        onChange={handleChange}
                        className={styles.inputField}
                    />
                    <DialogActions className={styles.dialogActions}>
                        <Button onClick={onClose} className={styles.cancelButton}>
                            Cancel
                        </Button>
                        <Button type="submit" className={styles.submitButton}>
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>

    );
};

export default FormDialog;





// <Dialog open={open} onClose={onClose}>
//     <DialogTitle style={{ color: "black", fontStyle: "inherit" }}>For disputes related to land or any other property issues, please submit your details, and our team will assist you in resolving the problem</DialogTitle>
//     <DialogContent>
//         <form onSubmit={handleSubmit}>
//             <TextField
//                 autoFocus
//                 margin="dense"
//                 name="name"
//                 label="Name"
//                 type="text"
//                 fullWidth
//                 variant="outlined"
//                 value={formData.name}
//                 onChange={handleChange}
//             />
//             <TextField
//                 margin="dense"
//                 name="email"
//                 label="Email"
//                 type="email"
//                 fullWidth
//                 variant="outlined"
//                 value={formData.email}
//                 onChange={handleChange}
//             />
//             <TextField
//                 margin="dense"
//                 name="mobile_number"
//                 label="Phone Number"
//                 type="text"
//                 fullWidth
//                 variant="outlined"
//                 value={formData.mobile_number}
//                 onChange={handleChange}
//             />
//             <TextField
//                 margin="dense"
//                 name="problem_title"
//                 label="Problem Title"
//                 type="text"
//                 fullWidth
//                 variant="outlined"
//                 value={formData.problem_title}
//                 onChange={handleChange}
//             />
//             <TextField
//                 margin="dense"
//                 name="problem_description"
//                 label="Problem Description"
//                 type="text"
//                 multiline
//                 rows={4}
//                 fullWidth
//                 variant="outlined"
//                 value={formData.problem_description}
//                 onChange={handleChange}
//             />
//             <DialogActions>
//                 <Button onClick={onClose} color="primary">
//                     Cancel
//                 </Button>
//                 <Button type="submit" color="primary">
//                     Submit
//                 </Button>
//             </DialogActions>
//         </form>
//     </DialogContent>
// </Dialog> 