import React from 'react';

const Contacts = () => {
  return (
    <div style={{backgroundColor:'black'}}>
        <h1> Contacts page</h1>
      
    </div>
  );
}

export default Contacts;
