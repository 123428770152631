// import React, { useState } from 'react';
// import { Tabs, Tab, Typography, Box } from '@mui/material';
// import '../styles/Tabs.module.css'

// interface TabData {
//     label: string;
//     content: React.ReactNode;
// }

// interface TabsComponentProps {
//     tabs: TabData[];
// }

// const StyledTabs: React.FC<TabsComponentProps> = ({ tabs }) => {
//     const [value, setValue] = useState(0);

//     const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//         setValue(newValue);
//     };

//     return (
//         <Box sx={{ width: '100%', mt: 2 }}>
//             <Tabs
//                 value={value}
//                 onChange={handleChange}
//                 selectionFollowsFocus // Added to follow focus selection
//                 TabIndicatorProps={{ style: { backgroundColor: '#00bfa5' } }}
//                 className="right-aligned-tabs"
//                 style={{ backgroundColor: "#d7dbdd" }}
//             >
//                 {tabs.map((tab, index) => (
//                     <Tab key={index} label={tab.label} className="tab-label" />
//                 ))}
//             </Tabs>
//             <Box className="tab-content">
//                 {tabs.map((tab, index) => (
//                     <TabPanel key={index} value={value} index={index}>
//                         {tab.content}
//                     </TabPanel>
//                 ))}
//             </Box>
//         </Box>
//     );
// };

// interface TabPanelProps {
//     children?: React.ReactNode;
//     index: number;
//     value: number;
// }

// const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`tabpanel-${index}`}
//             aria-labelledby={`tab-${index}`}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// };

// export default StyledTabs;



import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import '../styles/Tabs.module.css';

interface TabData {
    label: string;
    value: string;
    content: React.ReactNode;
}

interface TabsComponentProps {
    tabs: TabData[];
    onTabChange: (value: string) => void;
    activeTab: string;
}

const StyledTabs: React.FC<TabsComponentProps> = ({ tabs, onTabChange, activeTab }) => {
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        onTabChange(newValue); // This triggers tab change in the parent component
    };

    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            <Tabs
                value={activeTab}
                onChange={handleChange}
                selectionFollowsFocus
                TabIndicatorProps={{ style: { backgroundColor: '#00bfa5' } }}
                className="right-aligned-tabs"
                style={{ backgroundColor: '#d7dbdd' }}
            >
                {tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} className="tab-label" />
                ))}
            </Tabs>
            <Box className="tab-content">
                {tabs.map((tab) => (
                    <TabPanel key={tab.value} value={activeTab} index={tab.value}>
                        {tab.content}
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export default StyledTabs;
