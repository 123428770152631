// import React, { useState, useEffect } from 'react'
// import style from '../../styles/OwnerProperty.module.css'
// import { getusertypeproperties, getProperties } from '../../services/Api/Property'
// import PropertyCard from '../PropertyCard'
// import StyledTabs from '../../Components/Tabs'
// import styles from '../../styles/HomePage.module.css'
// import {
//     Typography,
// } from '@mui/material';


// const OwnerProperties = () => {

//     const [data, setData] = useState([])

//     useEffect(() => {

//         const getMyProperties = async () => {
//             try {
//                 let user;
//                 const userItem = localStorage.getItem('user')
//                 if (userItem) {
//                     user = JSON.parse(userItem);
//                 }
//                 const response = await getusertypeproperties('PENDING', user.id);
//                 if (response.success && user) {
//                     const userProperty = response.data.filter((item: any) => item.user_id == user.id)

//                     setData(userProperty)
//                 }
//             }
//             catch (err) {
//                 console.log("error occured")
//             }
//         }
//         getMyProperties()
//     }, [])

//     const tabsData = [
//         {
//             label: 'ALL Properties',
//             content: (
//                 <div>
//                     <img src="https://via.placeholder.com/80" alt="Web Apps" />
//                     <Typography>Web Apps</Typography>
//                 </div>

//             ),
//         },
//         {
//             label: 'Approved Properties',
//             content: (
//                 <div>
//                     <img src="https://via.placeholder.com/80" alt="Web Apps" />
//                     <Typography>Web Apps</Typography>
//                 </div>
//             ),
//         },
//         {
//             label: 'Pending Properties',
//             content: (
//                 <div className={styles.propertycontainer}>
//                     {
//                         data && data.map((item, index) => {
//                             return (
//                                 <div key={index}>
//                                     <PropertyCard property_obj={item} showButtons={false} />
//                                 </div>
//                             )
//                         })
//                     }
//                 </div>
//             ),
//         },
//         {
//             label: 'Rejected Properties',
//             content: (
//                 <div>
//                     <img src="https://via.placeholder.com/80" alt="Responsive" />
//                     <Typography>Responsive</Typography>
//                 </div>
//             ),
//         },
//     ];

//     return (
//         <div className={style.maincontainer}>
//             <div>
//                 <h2 style={{ fontStyle: "inherit", color: "red" }}>Seller Dash Board</h2>
//             </div>
//             <StyledTabs tabs={tabsData} />

//             {
//                 data && data.length == 0 && (
//                     <>
//                         <p>You haven't added any properties yet.</p>
//                     </>
//                 )
//             }
//         </div>

//     )
// }

// export default OwnerProperties


import React, { useState, useEffect } from 'react';
import style from '../../styles/OwnerProperty.module.css';
import { getusertypeproperties } from '../../services/Api/Property'; // Assuming the same API call works for all
import PropertyCard from '../PropertyCard';
import StyledTabs from '../../Components/Tabs';
import styles from '../../styles/HomePage.module.css';
import { Typography } from '@mui/material';

const OwnerProperties = () => {
    const [data, setData] = useState<any>({ ALL: [], APPROVED: [], PENDING: [], REJECTED: [] });
    const [activeTab, setActiveTab] = useState('ALL');
    const [loading, setLoading] = useState(false);

    const fetchProperties = async (status: string) => {
        try {
            const userItem = localStorage.getItem('user');
            if (!userItem) {
                throw new Error('User not found in localStorage');
            }

            const user = JSON.parse(userItem);
            setLoading(true);

            // Fetching properties from API
            const response = await getusertypeproperties(status, user.id);

            setLoading(false); // Stop loading after the response is received

            // Check if the response was successful
            if (response.success) {
                const userProperties = response.data.filter((item: any) => item.user_id === user.id);

                // Update the state with the fetched data
                setData((prevData: any) => ({ ...prevData, [status]: userProperties }));

                console.log("Fetched Properties for status:", status, userProperties);
            } else {
                console.error('Failed to fetch properties. Response:', response);
            }
        } catch (err) {
            setLoading(false);
            console.error('Error fetching properties:', err);
        }
    };

    useEffect(() => {
        fetchProperties(activeTab);
    }, [activeTab]);

    // Tabs data
    const tabsData = [
        {
            label: 'ALL Properties',
            value: 'ALL',
            content: (
                <div className={styles.propertycontainer}>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : (
                        data.ALL.map((item: any, index: number) => (
                            <div key={index}>
                                <PropertyCard property_obj={item} showButtons={false} />
                            </div>
                        ))
                    )}
                </div>
            ),
        },
        {
            label: 'Approved Properties',
            value: 'APPROVED',
            content: (
                <div className={styles.propertycontainer}>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : (
                        data.APPROVED.map((item: any, index: number) => (
                            <div key={index}>
                                <PropertyCard property_obj={item} showButtons={false} />
                            </div>
                        ))
                    )}
                </div>
            ),
        },
        {
            label: 'Pending Properties',
            value: 'PENDING',
            content: (
                <div className={styles.propertycontainer}>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : (
                        data.PENDING.map((item: any, index: number) => (
                            <div key={index}>
                                <PropertyCard property_obj={item} showButtons={false} />
                            </div>
                        ))
                    )}
                </div>
            ),
        },
        {
            label: 'Rejected Properties',
            value: 'REJECTED',
            content: (
                <div className={styles.propertycontainer}>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : (
                        data.REJECTED.map((item: any, index: number) => (
                            <div key={index}>
                                <PropertyCard property_obj={item} showButtons={false} />
                            </div>
                        ))
                    )}
                </div>
            ),
        },
    ];

    return (
        <div className={style.maincontainer}>
            <div>
                <h2 style={{ fontStyle: 'inherit', color: 'red' }}>Seller Dash Board</h2>
            </div>
            <StyledTabs tabs={tabsData} onTabChange={setActiveTab} activeTab={activeTab} />
            {data[activeTab]?.length === 0 && !loading && (
                <Typography>You haven't added any properties yet.</Typography>
            )}
        </div>
    );
};

export default OwnerProperties;



// const OwnerProperties = () => {
//     const [data, setData] = useState<any>({});
//     const [selectedTab, setSelectedTab] = useState('ALL'); // Track selected tab.

//     const fetchProperties = async (status: string) => {
//         try {
//             const userItem = localStorage.getItem('user');
//             if (!userItem) return;
//             const user = JSON.parse(userItem);

//             const response = await getusertypeproperties(status, user.id);
//             if (response.success) {
//                 const userProperties = response.data.filter((item: any) => item.user_id === user.id);
//                 setData((prevData: any) => ({ ...prevData, [status]: userProperties }));
//             }
//         } catch (err) {
//             console.error('Error fetching properties:', err);
//         }
//     };

//     // Handle tab change and trigger API call accordingly.
//     const handleTabChange = (newTab: string) => {
//         setSelectedTab(newTab);
//         if (!data[newTab]) fetchProperties(newTab); // Fetch only if data not already loaded.
//     };

//     const tabsData = [
//         {
//             label: 'ALL Properties',
//             value: 'ALL',
//             content: (
//                 <div className={styles.propertycontainer}>
//                     {data['ALL']?.map((item: any, index: number) => (
//                         <div key={index}>
//                             <PropertyCard property_obj={item} showButtons={false} />
//                         </div>
//                     )) || <Typography>No properties found.</Typography>}
//                 </div>
//             ),
//         },
//         {
//             label: 'Approved Properties',
//             value: 'APPROVED',
//             content: (
//                 <div className={styles.propertycontainer}>
//                     {data['APPROVED']?.map((item: any, index: number) => (
//                         <div key={index}>
//                             <PropertyCard property_obj={item} showButtons={false} />
//                         </div>
//                     )) || <Typography>No approved properties found.</Typography>}
//                 </div>
//             ),
//         },
//         {
//             label: 'Pending Properties',
//             value: 'PENDING',
//             content: (
//                 <div className={styles.propertycontainer}>
//                     {data['PENDING']?.map((item: any, index: number) => (
//                         <div key={index}>
//                             <PropertyCard property_obj={item} showButtons={false} />
//                         </div>
//                     )) || <Typography>No pending properties found.</Typography>}
//                 </div>
//             ),
//         },
//         {
//             label: 'Rejected Properties',
//             value: 'REJECTED',
//             content: (
//                 <div className={styles.propertycontainer}>
//                     {data['REJECTED']?.map((item: any, index: number) => (
//                         <div key={index}>
//                             <PropertyCard property_obj={item} showButtons={false} />
//                         </div>
//                     )) || <Typography>No rejected properties found.</Typography>}
//                 </div>
//             ),
//         },
//     ];

//     useEffect(() => {
//         // Fetch all properties on component mount (default).
//         fetchProperties('ALL');
//     }, []);