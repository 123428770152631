// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OwnerProperty_maincontainer__R8jPd{
    margin:3rem 10rem;
    box-shadow:10px 10px 20px rgba(0.1,0,0,0.1);
    padding: 1rem;
    border-radius:1rem;
}
.OwnerProperty_propertycontainer__PPNBf{
    margin:3rem 6rem;
    display:flex;
    gap:1rem;
    flex-wrap:wrap;
}`, "",{"version":3,"sources":["webpack://./src/styles/OwnerProperty.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,2CAA2C;IAC3C,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,QAAQ;IACR,cAAc;AAClB","sourcesContent":[".maincontainer{\n    margin:3rem 10rem;\n    box-shadow:10px 10px 20px rgba(0.1,0,0,0.1);\n    padding: 1rem;\n    border-radius:1rem;\n}\n.propertycontainer{\n    margin:3rem 6rem;\n    display:flex;\n    gap:1rem;\n    flex-wrap:wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maincontainer": `OwnerProperty_maincontainer__R8jPd`,
	"propertycontainer": `OwnerProperty_propertycontainer__PPNBf`
};
export default ___CSS_LOADER_EXPORT___;
