// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.module.css */
/* 
.App {
  text-align: center;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: top;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App button {
  font-size: 1.2rem;
  padding: 10px 20px;
  margin-top: 10px;
  color: white;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
} */

/* .App button:hover {
  background-color: #52cdef;
} */`, "",{"version":3,"sources":["webpack://./src/styles/App.module.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;;;;;;;;;;GAUG;;AAEH;;;;;;;;;;GAUG;;AAEH;;GAEG","sourcesContent":["/* App.module.css */\n/* \n.App {\n  text-align: center;\n  background-color: white;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: top;\n  font-size: calc(10px + 2vmin);\n  color: white;\n} */\n\n/* .App button {\n  font-size: 1.2rem;\n  padding: 10px 20px;\n  margin-top: 10px;\n  color: white;\n  background-color: #61dafb;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n} */\n\n/* .App button:hover {\n  background-color: #52cdef;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
