// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dialog Title */
.DisputeFormdialogue_dialogTitle__z3cko {
  font-size: 1.25rem;
  font-weight: bold;
  color: #007bff;
  text-align: center;
  margin-bottom: 15px;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  line-height: 1.5;
}

/* Form */
.DisputeFormdialogue_form__yT1Gk {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Input Fields */
.DisputeFormdialogue_inputField__X54O4 {
  background-color: #f9f9f9;
  border-radius: 5px;
}

/* Dialog Actions */
.DisputeFormdialogue_dialogActions__\\+TZA1 {
  justify-content: center;
  margin-top: 15px;
}

/* Buttons */
.DisputeFormdialogue_cancelButton__QxiKk {
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ccc;
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
}

.DisputeFormdialogue_cancelButton__QxiKk:hover {
  background-color: #e2e6ea;
}

.DisputeFormdialogue_submitButton__fMswF {
  background-color: #007bff;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 1rem;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.DisputeFormdialogue_submitButton__fMswF:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/styles/DisputeFormdialogue.module.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA,SAAS;AACT;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA,iBAAiB;AACjB;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA,mBAAmB;AACnB;EACE,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA,YAAY;AACZ;EACE,yBAAyB;EACzB,WAAW;EACX,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Dialog Title */\n.dialogTitle {\n  font-size: 1.25rem;\n  font-weight: bold;\n  color: #007bff;\n  text-align: center;\n  margin-bottom: 15px;\n  padding: 15px;\n  border-bottom: 1px solid #ddd;\n  line-height: 1.5;\n}\n\n/* Form */\n.form {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n/* Input Fields */\n.inputField {\n  background-color: #f9f9f9;\n  border-radius: 5px;\n}\n\n/* Dialog Actions */\n.dialogActions {\n  justify-content: center;\n  margin-top: 15px;\n}\n\n/* Buttons */\n.cancelButton {\n  background-color: #f8f9fa;\n  color: #333;\n  border: 1px solid #ccc;\n  padding: 8px 20px;\n  border-radius: 5px;\n  font-size: 1rem;\n  text-transform: uppercase;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.cancelButton:hover {\n  background-color: #e2e6ea;\n}\n\n.submitButton {\n  background-color: #007bff;\n  color: #fff;\n  padding: 8px 20px;\n  border-radius: 5px;\n  font-size: 1rem;\n  text-transform: uppercase;\n  border: none;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.submitButton:hover {\n  background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogTitle": `DisputeFormdialogue_dialogTitle__z3cko`,
	"form": `DisputeFormdialogue_form__yT1Gk`,
	"inputField": `DisputeFormdialogue_inputField__X54O4`,
	"dialogActions": `DisputeFormdialogue_dialogActions__+TZA1`,
	"cancelButton": `DisputeFormdialogue_cancelButton__QxiKk`,
	"submitButton": `DisputeFormdialogue_submitButton__fMswF`
};
export default ___CSS_LOADER_EXPORT___;
