// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_right-aligned-tabs__pqEWe {
    display: flex;
    justify-content: flex-end;
    /* Align tabs to the right */
}

.Tabs_tab-label__\\+nSrG {
    font-size: 1rem;
    font-weight: bold;
    color: #555;
    text-transform: uppercase;
    min-width: 120px;
    transition: color 0.3s ease;
    background-color: blue;
}

.Tabs_tab-label__\\+nSrG.Tabs_Mui-selected__MSz5A {
    color: #00bfa5;
    /* Active tab color */
}

.Tabs_tab-content__uAcye {
    padding: 20px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Tabs.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".right-aligned-tabs {\n    display: flex;\n    justify-content: flex-end;\n    /* Align tabs to the right */\n}\n\n.tab-label {\n    font-size: 1rem;\n    font-weight: bold;\n    color: #555;\n    text-transform: uppercase;\n    min-width: 120px;\n    transition: color 0.3s ease;\n    background-color: blue;\n}\n\n.tab-label.Mui-selected {\n    color: #00bfa5;\n    /* Active tab color */\n}\n\n.tab-content {\n    padding: 20px;\n    border-top: 1px solid #e0e0e0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"right-aligned-tabs": `Tabs_right-aligned-tabs__pqEWe`,
	"tab-label": `Tabs_tab-label__+nSrG`,
	"Mui-selected": `Tabs_Mui-selected__MSz5A`,
	"tab-content": `Tabs_tab-content__uAcye`
};
export default ___CSS_LOADER_EXPORT___;
