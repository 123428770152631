import React, { useState } from 'react';
import registrationGif from '../assetts/registration.gif';
import styled from "../styles/registration.module.css";
import { Button, MenuItem, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useNavigate } from "react-router-dom"
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { createUser, deleteUser, sendOtp, validateOtp } from '../services/Api/Users';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OTPInput from './OTPInput';

export function Registration() {
    const navigate = useNavigate()
    const [role, setRole] = useState(1)
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyURL, setCompanyURL] = useState('')
    const [password, setPassword] = useState('')
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarContent, setSnackbarContent] = useState('')
    const roles = ['SELLER', 'BUYER'];
    const [profile, setProfile] = useState<string | null>(null);
    const [fileName, setFileName] = useState('')
    const [isUserVerfied, setUserVerfied] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleBack = () => {
        navigate('/');
    };

    const [otp, setOtp] = useState<string>('');

    const handleOtpChange = (value: string) => {
        setOtp(value);
    };

    const disableCheck = () => {
        if (role == 1) {
            if (name == '' || mobile == '' || email == '' || companyName == '' || companyURL == '' || password == '') {
                return true
            }
        }
        else {
            if (name == '' || mobile == '' || email == '' || password == '') {
                return true
            }
        }
        return false;
    }
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            setFileName(file.name)
            const reader = new FileReader();
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    setProfile(reader.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {


        try {
            const response = await sendOtp({ email: email, service: "register" })
            handleClickOpen();
        }
        catch (err) {
            console.log(err)
        }

    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    const handleOTPSubmit = async () => {

        alert(`Entered OTP: ${otp}`);
        handleClose()
        try {
            const response = await validateOtp({ otp: otp, "service": "register", email: email })

            if (response.success) {
                setSnackbarContent("OTP has been verified successfully!!!")
                setSnackbarOpen(true)

                try {
                    const payload = {
                        name: name,
                        mobile_number: mobile,
                        email: email,
                        password: password,
                        company_name: companyName,
                        company_portal: companyURL,
                        role: roles[role - 1],
                        profile_picture: profile

                    }
                    const response = await createUser(payload)
                    if (response.success) {
                        setName('')
                        setMobile('')
                        setCompanyName('')
                        setCompanyURL('')
                        setEmail('')
                        setPassword('')
                        setName('')
                        setSnackbarContent("Sucessfully Registered")
                        setSnackbarOpen(true)
                    }

                }
                catch (error) {
                    console.log(error)
                    setSnackbarContent("Error Ocurred")
                    setSnackbarOpen(true)
                }
            }
        }
        catch (error: any) {

            setSnackbarContent(error.response.data.msg)
            setSnackbarOpen(true)
        }
    };
    return (
        <>
            <div className={styled.maincontainer}>

                <div className={styled.formcontainer}>
                    {/* <div className={styled.title}>FILL THE DETAILS</div> */}
                    <div className={styled.detailscontainer}>
                        <h2 style={{ textAlign: 'center', paddingBottom: '20px', color: '#0056b3', fontWeight: 'bold'}}>REGISTRATION FORM</h2>
                        <ToggleButtonGroup

                            value={role}
                            exclusive
                            aria-label="Platform"
                            fullWidth
                            style={{
                                marginBottom: '1rem'
                            }}
                        >
                            <ToggleButton value={1} onClick={() => setRole(1)}>Seller</ToggleButton>
                            <ToggleButton value={2} onClick={() => setRole(2)}>Buyer</ToggleButton>

                        </ToggleButtonGroup>
                        <TextField
                            id="outlined-basic"
                            label="Full Name"
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth

                        />
                        <div className={styled.inputcontainer}>
                            <TextField
                                id="outlined-basic"
                                label="Mobile Number"
                                variant="outlined"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                fullWidth />
                            <TextField
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth />
                        </div>

                        {(role === 1 || role == 3 || role == 4) && (
                            <div className={styled.inputcontainer}>
                                <TextField
                                    id="outlined-basic"
                                    label="Company Name"
                                    variant="outlined"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Company URL"
                                    variant="outlined"
                                    value={companyURL}
                                    onChange={(e) => setCompanyURL(e.target.value)}
                                    fullWidth />
                            </div>
                        )}
                        <div className={styled.inputcontainer}>
                            <TextField
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type='password'
                                fullWidth />

                        </div>
                        <div>
                            <input type="file" accept="image/*" onChange={handleImageChange} />

                        </div>
                        <div className={styled.buttoncontainer}>
                            <button className={styled.closebtn} onClick={handleBack}>Back</button>
                            <button className={(disableCheck()) ? styled.disablebtn : styled.submitbtn} onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                message={snackbarContent}
                onClose={handleSnackbarClose}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Enter the OTP"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>OTP has been sent to your email please verify it expires in 10 minutes</p>
                        <OTPInput length={6} onChange={handleOtpChange} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOTPSubmit} autoFocus variant="contained" style={{ backgroundColor: 'black' }}>
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}