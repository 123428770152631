import React from 'react';
import { useParams } from 'react-router-dom';
import { getProperty } from '../../services/Api/Property';
import { PropertyResponseById } from '../../interfaces/Property-interface';
import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { Box } from '@material-ui/core';
import Carousel from '../Carousel';
import { faMapLocation, faCheckCircle, faSun, faWater, faLocationArrow, faEye, faTableTennis, faCheckDouble, faUser, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../../styles/PropertyDetails.module.css'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import Table from '@mui/material/Table';
import {
  faDumbbell,
  faShieldAlt,
  faSwimmingPool,
  faTree,
  faHouse,
  faBolt,
  faBasketballBall,
  faChalkboardTeacher,
  faCouch,
  faSpa,
  faFire,
  faCar,
  faWifi,
  faVideo,
  faTheaterMasks,
  faChild,
  faCut,
  faBicycle,

} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

const amenityIcons: { [key: string]: any } = {
  gymnasium: faDumbbell,
  security: faShieldAlt,
  "swimming pool": faSwimmingPool,
  "children's play area": faChild,
  "24x7 water supply": faBolt,
  "badminton court": faBasketballBall,
  "club house": faHouse,
  "jogging track": faBicycle,
  "indoor games": faCouch,
  "spa/sauna/steam": faSpa,
  "fire sprinklers": faFire,
  "car parking": faCar,
  "internet / wifi": faWifi,
  "24x7 cctv surveillance": faVideo,
  "open air theatre": faTheaterMasks,
  salon: faCut,
  "senior citizen siteout": faChalkboardTeacher,
  "basketball court": faBasketballBall,
  "conference room": faChalkboardTeacher,
  cafeteria: faTree,
};




const PropertyDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const toggleMoreDetails = () => {
    setShowMoreDetails(!showMoreDetails);
  };


  useEffect(() => {
    // Check if user is logged in
    const userItem = localStorage.getItem("user");
    if (userItem) {
      const user = JSON.parse(userItem);
      setIsLoggedIn(!!user); // Set isLoggedIn based on user data
    }

    if (id) {
      getProperty(id)
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
          alert("Failed to fetch Data")
          console.error("Failed to fetch data:", error);
        });
    }
  }, [id]);

  const amenities =
    data?.features?.split(",").map((item: string) => item.trim()) || [];


  const handleMoreDetails = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div className={styles.propertyDetailsContainer}>
      {/* First Container: Always Visible */}
      <div className={styles.propertyCard}>
        <h5 className={styles.sectionTitle}>Property Details</h5>
        <div className={styles.propertyContent}>
          <Table className={styles.propertyTable}>
            {data?.property_name && (
              <tr>
                <th>Property Name</th>
                <td>{data.property_name}</td>
              </tr>
            )}
            {data?.price && (
              <tr>
                <th>Price</th>
                <td>
                  <CurrencyRupeeIcon /> {data.price}
                </td>
              </tr>
            )}
            {data?.property_status && (
              <tr>
                <th>Status</th>
                <td>{data.property_status}</td>
              </tr>
            )}
            {data?.facing && (
              <tr>
                <th>Facing</th>
                <td><FontAwesomeIcon icon={faSun} />{data.facing}</td>
              </tr>
            )}

            {data?.floors && (
              <tr>
                <th>Floors</th>
                <td>{data.floors}</td>
              </tr>
            )}
            {data?.size && (
              <tr>
                <th>Size</th>
                <td>{data.size}</td>
              </tr>
            )}
            {data?.address && (
              <tr>
                <th>Address</th>
                <td>
                  <LocationOnIcon /> {data.address}
                </td>
              </tr>
            )}
            {data?.near_by_places && (
              <tr>
                <th>Nearby Places:</th>
                <td><FontAwesomeIcon icon={faLocationArrow} /> {data.near_by_places}</td>
              </tr>
            )}
            {data?.district && (
              <tr>
                <th>District</th>
                <td>{data.district}</td>
              </tr>
            )}
            {data?.mandal && (
              <tr>
                <th>Mandal</th>
                <td>{data.mandal}</td>
              </tr>
            )}
            {data?.village && (
              <tr>
                <th>Village</th>
                <td>{data.village}</td>
              </tr>
            )}

            {data?.approved_by && (
              <tr>
                <th>Approved By</th>
                <td>{data.approved_by}</td>
              </tr>
            )}
            {data?.approved_no && (
              <tr>
                <th>Approved Number</th>
                <td>{data.approved_no}</td>
              </tr>
            )}
            {data?.negotiable && (
              <tr>
                <th>Is Negotiable</th>
                <td><FontAwesomeIcon icon={faCheckCircle} />{data.negotiable}</td>
              </tr>
            )}

            {data?.description && (
              <tr>
                <th>
                  Description
                </th>
                <td>{data.description}</td>
              </tr>

            )}
          </Table>
          {data?.images && data.images.length > 0 && (
            <div className={styles.imageSection}>
              <h2 className={styles.sectionTitle}>Images</h2>
              <Carousel images={data?.images} />
            </div>
          )}
          {data?.lastest_site_visit_images && data.lastest_site_visit_images.length > 0 && (
            <div>
              <h5 className={styles.sectionTitle}>Site Visiting Images</h5>
              <Carousel images={data?.lastest_site_visit_images} />
            </div>
          )}

          {data?.brochure && data.brochure.length > 0 && (
            <div>
              <h5 className={styles.sectionTitle}>Brochure</h5>
              <Carousel images={data?.brochure} />
            </div>
          )}
        </div>
        {/* Amenities Section */}
        <div className={styles.amenitiesContainer}>
          <h3 className={styles.sectionTitle}>Project Amenities</h3>
          <div className={styles.amenitiesGrid}>
            {amenities.length > 0 ? (
              amenities.map((amenity: string, index: number) => (
                <div className={styles.amenityItem} key={index}>
                  <FontAwesomeIcon
                    icon={amenityIcons[amenity.toLowerCase()] || faHouse}
                    className={styles.icon}
                  />
                  <p className={styles.amenityName}>{amenity}</p>
                </div>
              ))
            ) : (
              <p className={styles.noAmenities}>No amenities available</p>
            )}
          </div>
        </div>
        <button
          className={`${styles.toggleButton} ${showMoreDetails ? styles.hideDetails : ""
            }`}
          onClick={toggleMoreDetails}
        >
          {showMoreDetails ? "Hide Details" : "More Details"}
        </button>
        {/* <p onClick={toggleMoreDetails}><strong>{showMoreDetails ? "Hide Details" : "Want to see More Details.."}</strong></p> */}

        {showMoreDetails && (
          <div className={styles.detailsSection}>
            <h2 className={styles.sectionTitle}>Owner Details</h2>
            <div className={styles.ownerInfo}>
              <p>
                <FontAwesomeIcon icon={faUser} className={styles.icon} />{" "}
                <strong>Name:</strong> {data?.property_owner_name || "N/A"}
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />{" "}
                <strong>Email:</strong> {data?.owner_email || "N/A"}
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} className={styles.icon} />{" "}
                <strong>Phone:</strong> {data?.owner_phone_no || "N/A"}
              </p>
              <p>
                <strong>About Owner:</strong> {data?.about_owner || "N/A"}
              </p>
            </div>


          </div>
        )}
      </div>


      {/* <div className={styles.propertyDetailsContainer}> */}
      {/* <button
        className={`${styles.toggleButton} ${showMoreDetails ? styles.hideDetails : ""
          }`}
        onClick={toggleMoreDetails}
      >
        {showMoreDetails ? "Hide Details" : "More Details"}
      </button>

      {showMoreDetails && (
        <div className={styles.detailsSection}>
          <h2 className={styles.sectionTitle}>Owner Details</h2>
          <div className={styles.ownerInfo}>
            <p>
              <FontAwesomeIcon icon={faUser} className={styles.icon} />{" "}
              <strong>Name:</strong> {data?.property_owner_name || "N/A"}
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />{" "}
              <strong>Email:</strong> {data?.owner_email || "N/A"}
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} className={styles.icon} />{" "}
              <strong>Phone:</strong> {data?.owner_phone_no || "N/A"}
            </p>
            <p>
              <strong>About Owner:</strong> {data?.about_owner || "N/A"}
            </p>
          </div>


        </div>
      )} */}
      {/* </div> */}
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleMoreDetails}
        className={styles.moreDetailsButton}
      >
        More Details
      </Button> */}

      {/* Dialog Box for Second Container */}
      {/* <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {isLoggedIn ? "Owner Details and Media" : "Access Restricted"}
        </DialogTitle>
        <DialogContent>
          {isLoggedIn ? (
            <>
              <h5 className={styles.sectionTitle}>Owner Details</h5>
              <div className={styles.ownerInfo}>
                <p>
                  <FontAwesomeIcon icon={faUser} className={styles.icon} />{" "}
                  <strong>Name:</strong> {data?.property_owner_name || "N/A"}
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />{" "}
                  <strong>Email:</strong> {data?.owner_email || "N/A"}
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} className={styles.icon} />{" "}
                  <strong>Phone:</strong> {data?.owner_phone_no || "N/A"}
                </p>
                <p><strong>About Owner:</strong> {data?.about_owner || "N/A"}</p>
              </div>

              {data?.images && data.images.length > 0 && (
                <div>
                  <h5 className={styles.sectionTitle}>Images</h5>
                  <Carousel images={data?.images} />
                </div>
              )}

              {data?.lastest_site_visit_images && data.lastest_site_visit_images.length > 0 && (
                <div>
                  <h5 className={styles.sectionTitle}>Site Visiting Images</h5>
                  <Carousel images={data?.lastest_site_visit_images} />
                </div>
              )}

              {data?.brochure && data.brochure.length > 0 && (
                <div>
                  <h5 className={styles.sectionTitle}>Brochure</h5>
                  <Carousel images={data?.brochure} />
                </div>
              )}
            </>
          ) : (
            <p>
              To view more details, you must sign in to the system. Please log in to continue.
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
          {!isLoggedIn && (
            <Button onClick={() => window.location.href = "/login"} color="primary">
              Log In
            </Button>
          )}
        </DialogActions>
      </Dialog> */}
    </div >
  );
}

export default PropertyDetails;
