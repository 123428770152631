// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Events_maincontainer__tQLmt {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .Events_form__oYN0a {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  
  .Events_formGroup__rbYoP {
    margin-bottom: 15px;
  }
  
  .Events_label__aLcg0 {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .Events_input__cvbiM,
  .Events_textarea__jFnP8 {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .Events_textarea__jFnP8 {
    resize: vertical;
  }
  
  .Events_button__fEzc7 {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .Events_imagePreview__iThgv {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Events.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;;IAEE,WAAW;IACX,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;EACpB","sourcesContent":[".maincontainer {\n    padding: 20px;\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  h1 {\n    text-align: center;\n    margin-bottom: 30px;\n  }\n  \n  .form {\n    background-color: #f9f9f9;\n    padding: 20px;\n    border-radius: 8px;\n    border: 1px solid #ccc;\n  }\n  \n  .formGroup {\n    margin-bottom: 15px;\n  }\n  \n  .label {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 5px;\n  }\n  \n  .input,\n  .textarea {\n    width: 100%;\n    padding: 10px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .textarea {\n    resize: vertical;\n  }\n  \n  .button {\n    padding: 10px 20px;\n    font-size: 16px;\n    color: white;\n    background-color: #007bff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .imagePreview {\n    width: 150px;\n    height: 150px;\n    object-fit: cover;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maincontainer": `Events_maincontainer__tQLmt`,
	"form": `Events_form__oYN0a`,
	"formGroup": `Events_formGroup__rbYoP`,
	"label": `Events_label__aLcg0`,
	"input": `Events_input__cvbiM`,
	"textarea": `Events_textarea__jFnP8`,
	"button": `Events_button__fEzc7`,
	"imagePreview": `Events_imagePreview__iThgv`
};
export default ___CSS_LOADER_EXPORT___;
