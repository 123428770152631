// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Apartments_container__T6dQy {
  margin: 20px;
  padding: 10px 10rem;
}

.Apartments_divider__SYnBR {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.Apartments_header__LwBbo {
  font-size: 24px;
  color: #333;
}

.Apartments_styles_search__T0fuE {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 400px;
}

.Apartments_cardContainer__oM7QY {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Apartments_card__LDI-6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.33% - 20px);
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.Apartments_card__LDI-6:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

.Apartments_image__9R8g7 {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.Apartments_title__4nR2O {
  padding: 10px;
  font-size: 23px;
  font-weight: bold;
}

.Apartments_description__Ne5dK {
  padding: 10px;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Apartments.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,YAA6B;EAA7B,cAA6B;EAA7B,+BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,0CAA0C;EAC1C,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".container {\n  margin: 20px;\n  padding: 10px 10rem;\n}\n\n.divider {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.header {\n  font-size: 24px;\n  color: #333;\n}\n\n.styles_search {\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  width: 400px;\n}\n\n.cardContainer {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.card {\n  flex: 0 0 calc(33.33% - 20px);\n  margin: 10px;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-sizing: border-box;\n}\n\n.card:hover {\n  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);\n  transform: scale(1.02);\n}\n\n.image {\n  width: 100%;\n  height: 300px;\n  object-fit: cover;\n  border-top-left-radius: 5px;\n  border-top-right-radius: 5px;\n}\n\n.title {\n  padding: 10px;\n  font-size: 23px;\n  font-weight: bold;\n}\n\n.description {\n  padding: 10px;\n  font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Apartments_container__T6dQy`,
	"divider": `Apartments_divider__SYnBR`,
	"header": `Apartments_header__LwBbo`,
	"styles_search": `Apartments_styles_search__T0fuE`,
	"cardContainer": `Apartments_cardContainer__oM7QY`,
	"card": `Apartments_card__LDI-6`,
	"image": `Apartments_image__9R8g7`,
	"title": `Apartments_title__4nR2O`,
	"description": `Apartments_description__Ne5dK`
};
export default ___CSS_LOADER_EXPORT___;
